.observerSec {
    height: 110vh;
}
.exploreAct {
    transform-origin: center;

}

.explore-btn {
    margin-top: 30px;
    padding: 10px 80px;
    position: relative;
    color: white;
    overflow: hidden;
}

.explore-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1d1d1d;
    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
}

.explore-btn:hover::before {
    transform: translate3d(0, -100%, 0);
}


.touch-click {
    position: relative;
    border-radius: 10px;
    background: #1d1d1d;
    text-transform: uppercase;
    color: white;
    overflow: hidden;
}

.touch-click::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 10px;
    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
}

.touch-click:hover::before {
    transform: translate3d(0, -100%, 0);
}

/*
.button--bestia {
    color: #fff;
    background: none;
    padding: 0;
    display: flex;
        justify-content: center;
        align-items: center;
}

.button--bestia .button__bg {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50% !important;
        background: #e7e7e7bf;
    border-radius: 0.85rem;
    overflow: hidden;
    mix-blend-mode: difference;
    transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.button--bestia:hover .button__bg {
    transform: scale3d(1.2, 1.2, 1);
}

.button--bestia .button__bg::before,
.button--bestia .button__bg::after {
    content: '';
    position: absolute;
    background: #0000007d;
}

.button--bestia .button__bg::before {
    width: 110%;
    height: 0;
    padding-bottom: 110%;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 1);
}

.button--bestia:hover .button__bg::before {
    transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
}

.button--bestia .button__bg::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s;
}

.button--bestia:hover .button__bg::after {
    opacity: 1;
    transition-duration: 0.01s;
    transition-delay: 0.3s;
}

.button--bestia span {
    display: block;
    position: relative;
    text-align: center;
    
        text-transform: uppercase;
        mix-blend-mode: difference;
    }
button--bestia:hover span {
    display: block;
    position: relative;
    text-align: center;
    color: white;
    text-transform: uppercase;
    mix-blend-mode: difference;
} */

/* .exploreAct:hover .front-flip {
    opacity: 0 !important;
}

.exploreAct:hover .back-flip {
    opacity: 1 !important;
}
*/
.prod2 {
    background-repeat: no-repeat !important;
    background-size: contain !important;
    clip-path: inset(0px 0px 0%);
}
.baseImage {
    background-size: 100% 100% !important;
}