@keyframes shimmer {
    100% {
      background-position: left
    }
  }
.footer-section {
    color: white;
    overflow: hidden;
    /* mask: linear-gradient(-60deg, #000 30%, #00000088, #000 70%) right/350% 100%; */
    /* animation: shimmer 2.5s infinite; */
}

.footer-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    /* background: linear-gradient(-60deg, #000000 30%, #b9a49dfc, #000000 70%) right / 350% 100%; */
    /* animation: shimmer 2.5s infinite; */
    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
}

.footer-section:hover::before {
    transform: translate3d(0, -100%, 0);
}