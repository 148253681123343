.service-section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-banner {
    position: relative;
    width: 100%;
    border-radius: 30px;
    background-position: center !important;
    background-size: cover !important;
}

.service-title {
    position: absolute;
    top: -1px;
    left: -1px;
    border-bottom-right-radius: 20px;
    text-transform: uppercase;
    font-weight: 800;
    background-color: #0e0e0e;
    color: white;

}

.scroll-start-animation {
    view-timeline: --subjectReveal block;
    animation-timeline: --subjectReveal;
    animation-range-end: contain;
    animation-fill-mode: both;
}

.videobg-text {
    text-transform: uppercase;
    background: url('../../assets/bg_new.gif');
    color: #de466c;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.service-title::after {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 13px;
    box-shadow: -20px -20px 0px 0 #0e0e0e;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    top: 26px;
    right: -40px;
    z-index: 0;
}

.service-title::before {
    content: "";
    width: 50px;
    height: 50px;
    border-radius: 32%;
    box-shadow: -20px -20px 0px 0 #0e0e0e;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    bottom: -50px;
    left: 25px;
    z-index: 0;
}

.service-detail {
    position: absolute;
    bottom: -1px;
    right: -1px;
    width: 45%;
    max-width: 437px;
    border-top-left-radius: 20px;
    border-inline: var(40px) solid #0000;
    border-radius: calc(2*var(40px)) calc(2*var(40px)) 0 0/var(40px);
    background-color: #0e0e0e;
    color: white;

}

.scroll-end-animation {
    view-timeline: --subjectReveal block;
    animation-timeline: --subjectReveal;
    animation-range-end: cover;
    animation-fill-mode: both;
}

.service-detail::after {
    content: "";
    width: 50px;
    height: 50px;
    border-radius: 32%;
    box-shadow: 20px 19px 0px 0 #0e0e0e;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    top: -25px;
    right: 1px;
    z-index: 0;
}

.service-detail::before {
    content: "";
    width: 50px;
    height: 50px;
    border-radius: 32%;
    box-shadow: 20px 19px 0px 0 #0e0e0e;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    bottom: 1px;
    left: -25px;
    z-index: 0;
}

.MuiSvgIcon-root {
    background: url('../../assets/bg_new.gif') !important;
    color: transparent !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    display: block;
    aspect-ratio: 1;
    background: #F8CA00;
    clip-path: polygon(50% 0,
            calc(50%*(1 + sin(.4turn))) calc(50%*(1 - cos(.4turn))),
            calc(50%*(1 - sin(.2turn))) calc(50%*(1 - cos(.2turn))),
            calc(50%*(1 + sin(.2turn))) calc(50%*(1 - cos(.2turn))),
            calc(50%*(1 - sin(.4turn))) calc(50%*(1 - cos(.4turn))));
}
