*::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}
.animated {
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}
.pageSection {
    width: 100%;
    height: 100vh;
    /* background: url('../assets/bg_new.gif');
                    background-repeat: no-repeat;
                    background-size: cover; */
    position: relative;
}
.split-parent {
    overflow: hidden;
}

.split-child {
    display: inline-block;
}
.videobg {
    background: url('../assets/bg_new.gif');
    background-repeat: no-repeat;
    background-size: cover;
}
.landing-section {
    width: 100%;
    position: relative;
}

.banner-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.banner-h2 {
    font-size: 270.34px;
}

.banner-h4 {
    font-size: 60px;
    font-weight: 800;
    line-height: 60px;
}

.banner-h4-subtitle {
    font-size: 50px;
    font-weight: 400;
    line-height: 60px;
    white-space: nowrap;
}

.banner-subtitle {
    text-align: start;
    font-size: 34px !important;
        font-weight: 500 !important;
        /* margin-top: -100px !important; */
    margin-left: 12px !important;
}

.landing-video {
    width: 100vw;
    min-width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -0;
}

.section-h3 {
    text-transform: uppercase;
    background: url('../assets/bg_new.gif');
    color: #de466c;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}