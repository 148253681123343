.faq-title {}


.landing-wrapper {
    width: 100%;
    /* height: 6110px; */
    overflow-x: hidden;
}

.landing-inner-content {
    width: 3000px;
    position:relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.box {
    position: relative;
    margin: 20px;
    height:100%;
    min-width: 318px;
    /* width: 500px; */
}
.box-content {
    margin-left: auto;
    margin-right: auto;
}